<script>
export default {
	name: "HeaderPepper",
};
</script>

<template>
	<header>
		<img src="../assets/logos/pepper_logo_name.svg" alt="Pepper" />
	</header>
</template>

<style scoped>
header {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--item_space_simple);
	border-bottom: var(--border_min) solid var(--grayop6);
}
</style>
