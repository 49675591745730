import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "home",
		meta: { title: "Código - Pepper ID", disallowAuthed: true },
		component: () => import("../views/Code.vue"),
	},
	{
		path: "/documento",
		name: "document",
		meta: { title: "Documento - Pepper ID" },
		component: () => import("../views/Document.vue"),
	},
	{
		path: "/:pathMatch(.*)*",
		name: "404",
		component: () => import("../views/404.vue"),
	},
];

const DEFAULT_TITLE = "Pepper ID";

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem("token");
	nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
	});
	if (to.path === "/documento" && !token) {
		return next({ name: "home" });
	} else if (token && to.meta.disallowAuthed && to.path != "/documento") {
		return next({ name: "document" });
	} else if (token && to.meta.disallowAuthed && to.path == "/documento") {
		next(false);
	}
	next();
});

export default router;
