import { createApp } from "vue";
import Toast, { POSITION } from "vue-toastification";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import "vue-toastification/dist/index.css";
import "./registerServiceWorker";
import "./styles/root.css";
import "./styles/global.css";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App);

const options = {
	transition: "Vue-Toastification__bounce",
	timeout: 2000,
	maxToasts: 3,
	newestOnTop: true,
	position: POSITION.BOTTOM_RIGHT,
};

app.config.globalProperties.$axios = axios;

app.use(Toast, options);
app.use(router);

app.mount("#app");
