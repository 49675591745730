<script>
import HeaderPepper from "./components/HeaderPepper.vue";
import axios from "axios";

export default {
	data() {
		return {
			hasPermissionToSee: undefined,
		};
	},
	created() {
		var is_mobile =
			!!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

		if (!is_mobile) {
			this.hasPermissionToSee = false;
		} else {
			this.hasPermissionToSee = true;
		}

		axios.interceptors.request.use(async (config) => {
			if (localStorage.getItem("token")) {
				config.headers["Temporary-Token"] =
					localStorage.getItem("token");
			}

			return config;
		});
	},
	components: {
		HeaderPepper,
	},
};
</script>

<template>
	<div v-if="hasPermissionToSee">
		<HeaderPepper />
		<main>
			<router-view v-slot="{ Component }">
				<transition name="slide" mode="out-in">
					<component :is="Component"></component>
				</transition>
			</router-view>
		</main>
	</div>
	<div v-else id="bg-mobile-access">
		<figure id="img-mobile-access">
			<img src="@/assets/img/mobile-access.svg" alt="" />
		</figure>

		<section id="description-mobile-access">
			<p id="red">Ops!</p>
			<h1>
				Você não está <br />
				acessando por um celular
			</h1>
			<p>
				Para acessar essa página, visite o link <br />
				através de um aparelho celular.
			</p>
		</section>

		<footer id="logo-pepper">
			<img src="@/assets/logos/pepper_logo_name.svg" alt="" />
		</footer>
	</div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
</style>

<style scoped>
#bg-mobile-access {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
}

#img-mobile-access img {
	width: 1000px;
	margin-top: -50px;
	margin-left: 20px;
}

#description-mobile-access {
	text-align: center;
	margin-top: -300px;
}

#description-mobile-access h1 {
	font-size: 32px;
	font-weight: 400;
}

#description-mobile-access #red {
	color: #e02932;
	margin-bottom: 20px;
}

#description-mobile-access p {
	font-family: "Work Sans";
	font-size: 18px;
	font-weight: 300;
	color: #bab9c8;
	margin-top: 10px;
}

#logo-pepper img {
	width: 90px;
	margin-top: 50px;
}
</style>
